
import { defineComponent, PropType, toRefs, computed } from 'vue';
import { InvestmentProp } from '@/types/Investment';
import investmentModule from '@/modules/investment/investmentModule';
export default defineComponent({
  props: {
    investment: {
      type: Object as PropType<InvestmentProp>,
      default: () => ({} as InvestmentProp),
    },
    className: {
      type: String,
      default: 'sm:w-80',
    },
  },
  setup: (props) => {
    const { investmentStatus } = investmentModule();

    const { investment }: any = toRefs(props);

    const investmentAmount = computed(() => {
      return investment.value?.liquidated_at
        ? investment.value?.liquidateable
        : investment.value?.earnings === 0
        ? investment.value?.amount
        : investment.value?.earnings;
    });

    const { status, bgColor } = investmentStatus(props.investment);

    return { status, bgColor, investmentAmount };
  },
});
