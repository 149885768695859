<template>
  <div
    class="
      flex flex-col
      w-full
      p-6
      border
      cursor-pointer
      shadow-3xl
      rounded-xl
      hover:shadow-hover hover:border-invest-purple
    "
    :class="className"
  >
    <slot>
      <div class="inline-flex items-center">
        <div
          class="flex items-center justify-center w-8 h-8 rounded-full"
          :class="`bg-${bgColor}`"
        >
          <ion-icon name="briefcase" class="text-lg text-white" />
        </div>
        <h5
          class="ml-2 text-xs font-normal truncate"
          v-text="investment.name"
        />
      </div>
      <h4 class="mt-5 text-xl font-bold">
        {{ $filters.formatAmount(investmentAmount) }}
      </h4>
      <div class="flex justify-between mt-5 text-xs text-invest-darkgray">
        <p>Balance</p>
        <p :class="`text-${bgColor}`">{{ status }}</p>
      </div>
    </slot>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, toRefs, computed } from 'vue';
import { InvestmentProp } from '@/types/Investment';
import investmentModule from '@/modules/investment/investmentModule';
export default defineComponent({
  props: {
    investment: {
      type: Object as PropType<InvestmentProp>,
      default: () => ({} as InvestmentProp),
    },
    className: {
      type: String,
      default: 'sm:w-80',
    },
  },
  setup: (props) => {
    const { investmentStatus } = investmentModule();

    const { investment }: any = toRefs(props);

    const investmentAmount = computed(() => {
      return investment.value?.liquidated_at
        ? investment.value?.liquidateable
        : investment.value?.earnings === 0
        ? investment.value?.amount
        : investment.value?.earnings;
    });

    const { status, bgColor } = investmentStatus(props.investment);

    return { status, bgColor, investmentAmount };
  },
});
</script>
